const SEO = [
	{
		page: "home",
		description:
			"Το Agrafino είναι μια ευέλικτη τεχνολογική πλατφόρμα που εξειδικεύεται σε έξυπνες λύσεις για αυτοματισμούς σπιτιού και βιομηχανικές εφαρμογές. Συνδυάζει προηγμένο υλικό και λογισμικό για να προσφέρει ακριβή έλεγχο, αυτοματοποίηση και παρακολούθηση διαφόρων συστημάτων, όπως φωτισμός, ασφάλεια και άλλα.Το Agrafino επιτρέπει την απρόσκοπτη ενσωμάτωση με την υπάρχουσα υποδομή, παρέχοντας απομακρυσμένη διαχείριση μέσω διαδικτυακών και κινητών εφαρμογών. Η πλατφόρμα είναι εξαιρετικά προσαρμόσιμη και σχεδιάζεται σύμφωνα με τις ειδικές ανάγκες κάθε πελάτη, είτε για ενίσχυση της ασφάλειας, βελτιστοποίηση της ενεργειακής χρήσης ή τη δημιουργία ενός περιβάλλοντος που υποστηρίζει την ευημερία των κατοικίδιων και των ζώων. Το Agrafino δεσμεύεται να κάνει τις καθημερινές εργασίες πιο έξυπνες και πιο αποδοτικές, προσαρμοσμένες στις απαιτήσεις του κάθε πελάτη.",
		keywords: ["agrafino", "smart home", "automation"],
	},
];

export default SEO;

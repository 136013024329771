import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Fade } from "react-awesome-reveal";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Socials from "../components/about/socials";

import INFO from "../data/user";

import "./styles/contact.css";

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Contact | ${INFO.main.title}`}</title>
			</Helmet>

			<div className="page-content">
				<NavBar active="contact" />
				<div className="content-wrapper">
					<Fade>
						<div className="contact-container">
							<div className="title contact-title">
								Επικοινωνήστε μαζί μας
							</div>
							<div className="subtitle contact-subtitle">
								Έχετε ερωτήσεις ή χρειάζεστε μια προσαρμοσμένη
								στις ανάγκες σας έξυπνη λύση; Επικοινωνήστε με
								το agrafino — όπου κάνουμε τον κόσμο σας πιο
								έξυπνο. Επικοινωνήστε μαζί μας σήμερα!
							</div>
						</div>

						<div className="socials-container">
							<div className="contact-socials">
								<Socials />
							</div>
						</div>

						<div className="page-footer">
							<Footer />
						</div>
					</Fade>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Contact;

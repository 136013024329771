import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Fade } from "react-awesome-reveal";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Article from "../components/articles/article";

import INFO from "../data/user";
import SEO from "../data/seo";
import myArticles from "../data/articles";

import "./styles/articles.css";

const Articles = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projects | ${INFO.main.title}`}</title>
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<Fade>
						<div className="articles-main-container">
							<div className="articles-container">
								<div className="articles-wrapper">
									{myArticles.map((article, index) => (
										<div
											className="articles-article"
											key={(index + 1).toString()}
										>
											<Article
												key={(index + 1).toString()}
												title={article().title}
												description={
													article().description
												}
												link={"/project/" + (index + 1)}
											/>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="page-footer">
							<Footer />
						</div>
					</Fade>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Articles;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Fade } from "react-awesome-reveal";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/about.css";

const About = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Hardware | ${INFO.main.title}`}</title>
			</Helmet>

			<div className="page-content">
				<NavBar active="hardware" />
				<div className="content-wrapper">
					<Fade>
						<div className="about-container">
							<div className="about-main">
								<div className="about-right-side">
									<div className="title about-title">
										agrafino M3
									</div>

									<div className="subtitle about-subtitle">
										<ul>
											<li>
												SoC ESP32 microcontroler, WiFi
												2.4GHz
											</li>
											<li>Power supply: 12 or 24 VDC</li>
											<li>
												8 Digital Inputs 12 or 24 VDC
											</li>
											<li>
												8 Digital Outputs 12 or 24 VDC,
												PWM
											</li>
											<li>
												4 Analog Inputs 0-5 V ,12bit
											</li>
											<li>
												MODBUS RTU/TCPIP, MQTT, UART
												,I2C
											</li>
											<li>
												Ακριβής έλεγχος με συνδεσιμότητα
												Wi-Fi και Bluetooth για
												απομακρυσμένη παρακολούθηση και
												έλεγχο μέσω διαδικτυακών ή
												κινητών εφαρμογών
											</li>
											<li>
												Κατάλληλο για αυτοματισμούς
												σπιτιού ή βιομηχανικές εφαρμογές
											</li>
										</ul>
									</div>
								</div>

								<div className="about-left-side">
									<div className="about-image-container">
										<div className="about-image-wrapper">
											<img
												src="/agrafinoM3.png"
												alt="about"
												className="about-image"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="about-main">
							<div className="about-right-side">
								<div className="title about-title">
									agrafino A11
								</div>

								<div className="subtitle about-subtitle">
									<ul>
										<li>
											SoC ESP32 microcontroler, WiFi
											2.4GHz
										</li>
										<li>Power supply: 12 or 24 VDC</li>
										<li>16 Digital Inputs 12 or 24 VDC</li>
										<li>
											2 Digital Outputs 12 or 24 VDC, PWM
										</li>
										<li>4 Analog Inputs 0-5 V ,12bit</li>
										<li>GSM-4G/5G συνδεσιμότητα</li>
										<li>
											MODBUS RTU/TCPIP, MQTT, UART ,I2C
										</li>
										<li>
											Ακριβής έλεγχος με συνδεσιμότητα
											Wi-Fi και Bluetooth για
											απομακρυσμένη παρακολούθηση και
											έλεγχο μέσω διαδικτυακών ή κινητών
											εφαρμογών
										</li>
										<li>
											Κατάλληλο για αυτοματισμούς σπιτιού
											ή βιομηχανικές εφαρμογές
										</li>
									</ul>
								</div>
							</div>

							<div className="about-left-side">
								<div className="about-image-container">
									<div className="about-image-wrapper">
										<img
											src="/agrafinoM3.png"
											alt="about"
											className="about-image"
										/>
									</div>
								</div>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<div className="title about-title">
								συνδεσιμότητα
							</div>
							<div className="subtitle about-subtitle">
								Οι συσκευές agrafino έχουν την δυνατότητα
								διασύνδεσης με βιομηχανικούς ελεγκτές (PLC),
								καθώς και βιομηχανικά panel (HMI), WINCC
								(SCADA). Σε αυτά περιλαμβάνονται το LOGO! ,τα
								SIMATIC s7-1200, s7-1500, s7-300/400, καθώς και
								τα βιομηχανικά panel της SIEMENS και άλλων
								κατασκευαστών (Bejer κλπ), που διαθέτουν
								Ethernet θύρα επικοινωνίας. Επίσης έχουν την
								ικανότητα να συλλέγουν και να στέλνουν δεδομένα
								σε controllers I/O , ρυθμιστές θερμοκρασίας,
								PID, καταγραφικά ισχύος, μονάδες ζύγισης,
								ρυθμιστές στροφών (drives) και όποια άλλη
								συσκευή έχει πρωτόκολλο επικοινωνίας MODBUS
								RTU/TCPIP. Ακόμα υποστηρίζουν συνδεσιμότητα μέσω
								MQTT, UART και I2C κατάλληλες για κάθε είδους
								επικοινωνίας. Μέσω του MQTT μεταφέρονται
								δεδομένα ασύρματα σε πραγματικό χρόνο από
								διάφορους αισθητήρες και ενεργοποιητές. Επίσης η
								συνδεσιμότητα μέσω UART και I2C επιτρέπει την
								επικοινωνία με άλλες περιφερειακές συσκευές και
								αισθητήρες.
								<br />
								<br />
								Καταλαβαίνει κανείς ότι οι δυνατότητες
								επικοινωνίας των συσκευών agrafino είναι
								απεριόριστες !
							</div>
						</div>
						<div className="page-footer">
							<Footer />
						</div>
					</Fade>
				</div>
			</div>
		</React.Fragment>
	);
};

export default About;

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Fade } from "react-awesome-reveal";

import styled from "styled-components";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";

import INFO from "../data/user";
import myArticles from "../data/articles";

import "./styles/readArticle.css";

let ArticleStyle = styled.div``;

const ReadArticle = () => {
	const navigate = useNavigate();
	let { slug } = useParams();

	const article = myArticles[slug - 1];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [article]);

	ArticleStyle = styled.div`
		${article().style}
	`;

	return (
		<React.Fragment>
			<Helmet>
				<title>{`${article().title} | ${INFO.main.title}`}</title>
				<meta name="description" content={article().description} />
				<meta name="keywords" content={article().keywords.join(", ")} />
			</Helmet>

			<div className="page-content">
				<NavBar />

				<div className="content-wrapper">
					<Fade>
						<div className="read-article-container">
							<div
								className="read-article-back"
								onClick={() => navigate(-1)}
							>
								<img
									src="../back-button.png"
									alt="back"
									className="read-article-back-button"
								/>
							</div>

							<div className="read-article-wrapper">
								<div
									className="title read-article-title"
									style={{ fontSize: "22px" }}
								>
									{article().title}
								</div>

								<div className="read-article-body">
									{article().body}
								</div>
							</div>
						</div>
						<div className="page-footer">
							<Footer />
						</div>
					</Fade>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ReadArticle;

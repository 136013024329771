import React from "react";

function article_1() {
	return {
		title: "agrafino Smart Home",
		description:
			"Το agrafino Smart Home είναι μια διαδικτυακή εφαρμογή που προσφέρει απόλυτο έλεγχο του περιβάλλοντος του σπιτιού σας με το πάτημα ενός κουμπιού. Με τη δυνατότητα διαχείρισης των φώτων, του συστήματος συναγερμού και άλλων συσκευών, η εφαρμογή είναι συμβατή με οποιουσδήποτε διακόπτες ή φώτα της επιλογής σας. Είτε θέλετε να ενισχύσετε την ασφάλεια, να δημιουργήσετε την ιδανική ατμόσφαιρα, είτε απλώς να κάνετε τη ζωή σας πιο εύκολη, το agrafino Smart Home προσφέρει μια πλήρως προσαρμόσιμη εμπειρία. Παρακολουθήστε και ρυθμίστε τις λειτουργίες του σπιτιού σας από οπουδήποτε, όλα μέσω μιας κομψής και διαισθητικής διεπαφής που ταιριάζει απόλυτα στον τρόπο ζωής σας.",
		keywords: [],

		body: (
			<React.Fragment>
				<img className="article-image-container" src="/smartHome.png" />

				<div className="article-description">
					Το agrafino Smart Home διαδικτυακή εφαρμογή που συνδέει το
					agrafino M3 με συστήματα σπιτιού προσφέροντας ολοκληρωμένες
					λύσεις αυτοματισμού για το σπίτι. Σχεδιασμένο για να
					διευκολύνει τη ζωή των χρηστών, το agrafino Smart Home
					επιτρέπει τον έλεγχο και την παρακολούθηση διαφόρων
					συστημάτων, όπως φωτισμός, ασφάλεια μέσω διαδικτυακών και
					κινητών εφαρμογών. Η πλατφόρμα είναι συμβατή με μια ποικιλία
					συσκευών και διακοπτών, επιτρέποντας στους χρήστες να
					δημιουργήσουν ένα εξατομικευμένο και άνετο περιβάλλον.
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		title: "agrafino Bright Wings",
		description:
			"Το agrafino Bright Wings είναι μια καινοτόμος διαδικτυακή εφαρμογή σχεδιασμένη για να προσομοιώνει τους φυσικούς κύκλους του ηλιακού φωτός για τα ζώα, δημιουργώντας ένα περιβάλλον που μιμείται πιστά την ανατολή και τη δύση του ηλίου. Ιδανική για ιδιοκτήτες κατοικίδιων, εκτροφείς ή όσους φροντίζουν ζώα, αυτή η εφαρμογή ρυθμίζει αυτόματα τον φωτισμό ώστε να ευθυγραμμίζεται με τους φυσικούς ρυθμούς του ήλιου, προωθώντας πιο υγιείς και φυσικές συμπεριφορές. Με την εύχρηστη διεπαφή της, μπορείτε να ορίσετε, να παρακολουθήσετε και να βελτιστοποιήσετε προγράμματα φωτισμού που ενισχύουν την ευημερία των ζώων σας, είτε πρόκειται για πουλιά, ερπετά ή άλλα είδη που είναι ευαίσθητα στο φως. Φέρτε τη φύση στο σπίτι σας με το agrafino Bright Wings.",
		keywords: [],
		body: (
			<React.Fragment>
				<img
					className="article-image-container"
					src="/brightwings3.png"
				/>

				<div className="article-description">
					Το agrafino BrightWings είναι μια διαδικτυακή εφαρμογή που
					συνδέει το agrafino M3 με συστήματα φωτισμού σπιτιού,
					ιδιαίτερα σε περιβάλλοντα με κατοικίδια ή άλλα ζώα.
					Αυτοματοποιεί τις συνθήκες φωτισμού, όπως την προσομοίωση
					της ανατολής του ηλίου για να ξυπνούν τα πουλιά ή άλλα
					κατοικίδια με φυσικό τρόπο. Το σύστημα ρυθμίζει τα φώτα με
					βάση συγκεκριμένες συνθήκες και επιτρέπει στους χρήστες να
					παρακολουθούν και να ελέγχουν τα πάντα απευθείας από τις
					κινητές συσκευές τους. Επίσης, είναι ειδικά σχεδιασμένο για
					να υποστηρίζει διάφορα πρόσθετα χαρακτηριστικά σύμφωνα με
					τις ανάγκες του πελάτη. Για παράδειγμα, μπορεί να
					αυτοματοποιήσει ταΐστρες και συστήματα νερού για ζώα,
					προσφέροντας μια ευέλικτη λύση για διαφορετικά περιβάλλοντα.
				</div>
			</React.Fragment>
		),
	};
}

function article_3() {
	return {
		title: "agrafino Alarm System",
		description:
			"Το agrafino Alarm System είναι μια προηγμένη διαδικτυακή λύση ασφαλείας που σας επιτρέπει να ενεργοποιείτε και να παρακολουθείτε τον συναγερμό του σπιτιού ή της επιχείρησής σας από οπουδήποτε. Με ειδοποιήσεις σε πραγματικό χρόνο και δυνατότητες απομακρυσμένου ελέγχου, μπορείτε να οπλίζετε ή να αφοπλίζετε το σύστημά σας, ανεξάρτητα από το πού βρίσκεστε, εξασφαλίζοντας ότι η ιδιοκτησία σας είναι πάντα ασφαλής. Είτε βρίσκεστε στο σπίτι είτε εν κινήσει, το agrafino Alarm System σας προσφέρει ηρεμία, γνωρίζοντας ότι η ασφάλειά σας είναι πάντα στη διάθεσή σας.",
		keywords: [],
		body: (
			<React.Fragment>
				<img
					className="article-image-container"
					src="/alarmSystem.png"
				/>

				<div className="article-description">
					Το agrafino Alarm System είναι μια προηγμένη διαδικτυακή
					λύση ασφαλείας βασισμένη στο agrafino A11 που σας επιτρέπει
					να προστατεύετε το σπίτι ή την επιχείρησή σας με ευκολία. Με
					δυνατότητες απομακρυσμένου ελέγχου, μπορείτε να
					ενεργοποιείτε και να παρακολουθείτε τον συναγερμό σας από
					οπουδήποτε, μέσω κινητών ή διαδικτυακών εφαρμογών. Το
					σύστημα προσφέρει ειδοποιήσεις σε πραγματικό χρόνο,
					διασφαλίζοντας ότι είστε πάντα ενημερωμένοι για την
					κατάσταση της ασφάλειάς σας.Tο agrafino Alarm System
					διευκολύνει τη διαχείριση και σας προσφέρει ηρεμία,
					γνωρίζοντας ότι η ασφάλεια του χώρου σας είναι πάντα στη
					διάθεσή σας.
				</div>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1, article_2, article_3];

export default myArticles;

const INFO = {
	main: {
		title: "agrafino",
		name: "agrafino",
		email: "agrafino@gmail.com",
		logo: "../logo.png",
		phone: "+306977079416",
	},

	homepage: {
		title: "agrafino",
		description:
			"Συσκευές ιδανικές για λύσεις έξυπνου σπιτιού, που προσφέρει έλεγχο σε πραγματικό χρόνο, παρακολούθηση και αυτοματισμό. Υποστηρίζει διάφορες προσαρμοσμένες διαδικτυακές εφαρμογές σχεδιασμένες για τις ανάγκες των πελατών, όπως έξυπνα συστήματα συναγερμού, αυτοματοποίηση φωτισμού και άλλες εξειδικευμένες εφαρμογές. Με ενσωματωμένο Wi-Fi και Bluetooth, η συσκευή επιτρέπει την απρόσκοπτη ενσωμάτωση στο δίκτυο του σπιτιού, παρέχοντας δυνατότητα απομακρυσμένου ελέγχου και αυτοματοποίησης πολλαπλών έξυπνων συστημάτων μέσω φιλικών προς τον χρήστη διαδικτυακών διεπαφών. Οι εφαρμογές προσαρμόζονται στις συγκεκριμένες απαιτήσεις του πελάτη, εξασφαλίζοντας ότι κάθε εγκατάσταση έξυπνου σπιτιού είναι μοναδική και βελτιστοποιημένη για λειτουργικότητα και άνεση.",
	},
};

export default INFO;
